<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
   

        <banner title="professionals" :breadcrumb="[
           {
              label: 'Dashboard',
            },
            {
              label: 'Professionals',
            }
          ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card">
              <div class="card-body">
                <div class="row">
                  <div class="col text font">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder">Professionals </b>
                    </h4>
                  </div>
                  <div class="col text-end">
                    <button
                      title="Add New Professional"
                      @click="valueNullProfassional()"
                      type="button"
                      style="background-color: #f21300"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <strong>+ New</strong>
                    </button>
                    &nbsp;<button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#new-task-modal"
                      title="Filter"
                      class="btn text-white btn-sm"
                      style="background-color: #00364f"
                    >
                      <strong> <font-awesome-icon icon="filter" /></strong>
                    </button>
                  </div>
                  <div
                    class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1"
                    style="margin-top: -5px"
                  >
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
                <!-- start list area  -->
                <div class="row" style="margin-top: 5px">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table
                        class="table table-hover table-sm font"
                        style="font-size: 14px"
                      >
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th style="padding-left: 15px; padding-right: 25px">+</th>

                          <th>Code</th>
                          <!-- <th style="width:10%;">Photo</th> -->
                          <th>Name</th>
                          <th>Mobile</th>
                          <th>Email</th>
                          <th>Type</th>
                          <th>Services</th>
                          <th>Kyc Status</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody class="text">
                          <tr
                            v-for="(professional, index) in allprofessionals"
                            :key="index"
                          >
                            <td>
                              <span v-if="professional.photo">
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="
                                    this.$store.state.imgUrl +
                                    `/professional/${professional.id}/thumbs/${professional.photo}`
                                  "
                                  alt=""
                                />
                              </span>
                              <span v-else>
                                <img
                                  style="width: 30px; height: 30px"
                                  :src="this.$store.state.placeholderImg"
                                  alt=""
                                />
                              </span>

                              <!-- <img
                                style="width: 30px; height: 30px; border-radius: 2px"
                                :src="professionallogofinder(professional)"
                                alt=""
                              /> -->
                            </td>
                            <td class="text-truncate">{{ professional.code }}</td>
                            <td class="text-truncate">{{ professional.name }}</td>
                            <td class="text-truncate">{{ professional.phone }}</td>
                            <td class="text-truncate">{{ professional.email }}</td>
                            <td class="text-truncate">
                              {{ professional.professional_type.name }}
                            </td>
                            <td class="text-center">
                              <button
                                title="Services"
                                type="button"
                                @click="getServices(professional)"
                                data-bs-toggle="modal"
                                data-bs-target="#AdminServices"
                                class="btn text-white btn-sm"
                                style="
                                  background-color: rgb(242 19 1);
                                  padding: 5px 7px 5px 7px;
                                "
                              >
                                <b>
                                  <font-awesome-icon icon="link" />
                                </b>
                              </button>
                            </td>
                            <td style="padding: 0px 15px 0px 15px" class="text-truncate">
                              <span v-if="professional.professional_kyc">
                                <p
                                  v-if="
                                    professional.professional_kyc.kycStatus == 'reject'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: black;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Rejected</b>
                                </p>

                                <p
                                  v-if="
                                    professional.professional_kyc.kycStatus == 'approve'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: green;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Approved</b>
                                </p>

                                <p
                                  v-if="
                                    professional.professional_kyc.kycStatus == 'cancel'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: #f21300;
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Cancelled</b>
                                </p>

                                <p
                                  v-if="
                                    professional.professional_kyc.kycStatus == 'false'
                                  "
                                  class="text-white btn btn-sm"
                                  style="
                                   background-color: rgba(221, 164, 9, 0.767), 128, 0);
                                   width: 70px;
                                   box-shadow: 2px 2px 5px gray;
                                   border-radius: 50px;
                                   font-size: 10px;
                                   padding-top: 5px;
                                   padding-bottom: 5px;
                                   padding-left: 7px;
                                   padding-right: 7px;
                                   margin-top: 5px;
                                   margin-bottom: 5px;
                                 "
                                >
                                  <b> Pending</b>
                                </p>
                              </span>

                              <span v-else>
                                <p
                                  class="text-white btn btn-sm"
                                  style="
                                    background-color: rgba(221, 164, 9, 0.767), 128, 0);
                                    width: 70px;
                                    box-shadow: 2px 2px 5px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding-top: 5px;
                                    padding-bottom: 5px;
                                    padding-left: 7px;
                                    padding-right: 7px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b> Pending</b>
                                </p>
                              </span>
                            </td>
                            <td class="text-truncate">
                              <p
                                v-if="professional.loginStatus == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-truncate">
                              <div class="btn-group btn-group-sm" role="group">
                                <router-link
                                  title="View"
                                  :to="`professional-profile/${professional.id}`"
                                  type="button"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link>

                                <button
                                  title="Edit"
                                  @click="professionalEdit(professional)"
                                  type="button"
                                  class="btn btns btn-sm btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                                <button
                                  title="Kyc"
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#kycModal"
                                  @click="professionalkycValueAsign(professional)"
                                  class="btn btns btn-sm text-dark"
                                  style="
                                    background-color: yellow;
                                    padding: 5px 4.5px 5px 4.5px;
                                  "
                                >
                                  <font-awesome-icon icon="file" />
                                </button>

                                <button
                                  title="Verify"
                                  v-if="professional.professional_kyc"
                                  @click="professionalkycValueApprove(professional)"
                                  class="btn btns btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #1426f5;
                                    color: white;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#approoveKycModal"
                                >
                                  <b>
                                    <font-awesome-icon icon="tag" />
                                  </b>
                                </button>
                                <button
                                  title="Qualification"
                                  @click="getQualifications(professional)"
                                  class="btn btns btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #f21000;
                                    color: white;
                                  "
                                  data-bs-toggle="modal"
                                  data-bs-target="#qualification"
                                >
                                  <b>
                                    <i
                                      class="fa fa-graduation-cap"
                                      aria-hidden="true"
                                    ></i>
                                  </b>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">
                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of
                      {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" @click="loadprofessional(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadprofessional(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 3"
                        @click="loadprofessional(pagination.current_page - 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 2"
                        @click="loadprofessional(pagination.current_page - 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page > 1"
                        @click="loadprofessional(pagination.current_page - 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li
                        class="active page-item"
                        @click="loadprofessional(pagination.current_page)"
                      >
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadprofessional(pagination.current_page + 1)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadprofessional(pagination.current_page + 2)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadprofessional(pagination.current_page + 3)"
                      >
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadprofessional( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadprofessional( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadprofessional(pagination.last_page - 3)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadprofessional(pagination.last_page - 2)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li
                        class="page-item"
                        v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadprofessional(pagination.last_page - 1)"
                      >
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadprofessional(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadprofessional(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="kycModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="exampleModalLabel">
            <b>{{ kycModalHeading }}</b>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row" v-if="msgImgError">
            <div class="col">
              <div class="alert alert-danger p-1" role="alert">
                {{ msgImgError }}
              </div>
            </div>
          </div>
          <form
            @submit.prevent="
              professionalKycVal == 'false'
                ? professionalKycCreate()
                : professionalUpdateKyc()
            "
          >
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th>Documents</th>
                <th>Action</th>
              </tr>
              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront"
                      >Aadhaar Front <small style="color: #f21000">*</small></b
                    >
                    <input
                      @change="uploadAdharFront"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharFront"
                      type="file"
                      accept="image/*"
                    />
                    <input
                      type="number"
                      v-model="form.aadharNo"
                      class="form-control"
                      placeholder="Adhar Number"
                      style="height: 30px"
                      @change="lenthheker($event, 'professionalKycAddar')"
                      required
                    />
                    <span style="color: #f21000" id="professionalKycAddar"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.aadharNo">{{
                          form.errors.error.aadharNo[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharFront">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Aadhaar Back</b>
                    <input
                      @change="uploadAdharBack"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="AdharBack"
                      type="file"
                      accept="image/*"
                    />
                  </td>
                  <td class="text-truncate text-end">
                    <label for="AdharBack">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Pan No. <small style="color: #f21000">*</small></b>
                    <input
                      @change="uploadPanPhoto"
                      class="input-file"
                      style="position: absolute; top: -150000px"
                      id="panphoto"
                      type="file"
                      accept="image/*"
                    />
                    <!-- <input
                      type="tel"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px"
                      maxlength="10"
                      required
                    /> -->

                    <input
                      v-if="panVarifyingStatus == 'true'"
                      disabled
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px; text-transform: uppercase"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <input
                      v-else
                      type="tel"
                      maxlength="10"
                      v-model="form.panNo"
                      class="form-control"
                      placeholder="Pan Number"
                      style="height: 30px; text-transform: uppercase"
                      required
                      @change="businessPanVeryfied()"
                      @keydown="nameKeydown($event)"
                    />
                    <span style="color: #f21000" id="adminPanVeryfied"></span>
                    <small style="color: red" v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.pan">{{
                          form.errors.error.pan[0]
                        }}</span></span
                      ></small
                    >
                  </td>
                  <td class="text-truncate text-end">
                    <label for="panphoto">
                      <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/upload.png"
                      />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                v-if="panVarifyingStatus == 'true'"
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
              <button
                v-else
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!--start  model  link services -->

  <!-- modal qualification -->

  <!-- Modal -->
  <div
    class="modal fade"
    id="qualification"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Qualification</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">


              <ul class="list-group list-group-flush">
  <li class="list-group-item" v-for="(qualification, index) in qualifications" :key="index">
<!-- 
    <button type="button" class="btn btn-sm btn-outline-primary"  v-if="qualification.professionalqualification_id">ADD</button>
    <button type="button" class="btn btn-sm btn-outline-danger"  v-else>REMOVE</button> -->
    <input 
    v-if="qualification.professionalqualification_id"
    @click="professionaQualification($event, qualification)"
                  type="checkbox"
                  
                  checked
                />
                <input v-else
    @click="professionaQualification($event, qualification)"
                  type="checkbox"
                  
                />

                <label class="form-check-label" :for="`flexCheckDefault${index}`">
                &nbsp;  {{qualification.qualification}}
                </label>



  </li>
  
</ul>

             
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>

          <!-- <button
            type="button"
            class="btn text-white btn-sm"
            style="background-color: #00364f"
          >
            Confirm
          </button> -->
        </div>
      </div>
    </div>
  </div>

  <!-- end qualification  -->

  <!-- modal for link Admin services services  -->

  <div
    class="modal fade"
    id="AdminServices"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <b style="color: #00364f">Add professional Servicess</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm">
              <tr
                class="table-wrapper-scroll-y text-white rounded-circle"
                style="background-color: #00364f; height: 40px"
              >
                <th>+</th>
                <th class="text-truncate">Name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th class="text-truncate">Professional  Fee&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th class="text-truncate">
                  Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </th>
                <!-- <th>Status</th> -->
                <th>Action</th>
              </tr>

              <tbody>
                <tr
                  style="color: #00364f"
                  v-for="(service, index) in services"
                  :key="index"
                >
                  <td class="text-start text-truncate">
                    <div>
                      <!-- <input
                        @click="professionalServiceStatus(service, $event)"
                        class="form-check-input"
                        type="checkbox"
                        :value="service.id"
                        v-model="profServices"
                      /> -->

                      <input
                        v-if="service.profStatus == 'true'"
                        @click="professionalServiceStatus(service, 'false')"
                        type="checkbox"
                        checked
                      />
                      <!-- v-model="profServices" -->
                      <input
                        v-else
                        @click="professionalServiceStatus(service, 'true')"
                        type="checkbox"
                      />
                    </div>
                  </td>

                  
                  <td class="text-truncate">{{ service.name }}</td>
                  <td class="text-truncate">{{ service.professionalFee }}</td>
                  <td>
                    <input
                      type="number"
                      @change="professionalServiceCharge(service, $event)"
                      :value="service.professionalServiceFee"
                      class="form-control"
                      placeholder="Amount"
                      style="height: 30px; width: 80%"
                    />
                  </td>

                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="saveServiceChargesprofessional(service)"
                        class="btn btns text-white btn-sm"
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      >
                        <font-awesome-icon icon="floppy-disk" />
                      </button>
                      <!-- <button type="button" class="btn btns btn-sm btn-danger" style="padding:5px 4.5px 5px 4.5px;">
                        <font-awesome-icon icon="trash" />
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-sm text-white"
            style="background-color: #f21300"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <span v-if="edit == 'false'"> <strong>Add New Profassional </strong></span>
            <span v-else>
              <strong style="color: #00364f"> Edit Profassional </strong></span
            >
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="edit == 'false' ? saveprofessional() : professionalUpdate()"
          >
            <div class="row">
              <div class="col-12 text-center" v-if="msgImgError">
                <div class="alert alert-danger p-1" role="alert">
                  {{ msgImgError }}
                </div>
              </div>
              <div class="col-xl-12 col-md-12 col-12">
                <label for="file-input1">
                  <!-- <img style="width: 60px; height: 60px" :src="photoPreImg" /> -->
                  <img
                    style="width: 50px; height: 50px"
                    v-if="photoPreImg"
                    :src="photoPreImg"
                  />
                </label>
                <input
                  @change="profileImageUpload"
                  style="position: absolute; top: -15000px"
                  id="file-input1"
                  type="file"
                />
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Name <span style="color: #f21000">*</span></label>
                  <input
                    type="tel"
                    v-model="professional_form.name"
                    class="form-control"
                    placeholder="Name"
                    style="height: 30px"
                    @change="lenthheker($event, 'professionalName')"
                    required
                  />
                  <span style="color: #f21000" id="professionalName"></span>
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.name">{{
                        professional_form.errors.error.name[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Email <span style="color: #f21000">*</span></label>
                  <input
                    type="email"
                    v-model="professional_form.email"
                    class="form-control"
                    placeholder="Email"
                    style="height: 30px"
                    required
                  />
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.email">{{
                        professional_form.errors.error.email[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Password</label>
                  <input
                    style="height: 30px"
                    type="password"
                    v-model="professional_form.password"
                    class="form-control"
                    placeholder="PASSWORD"
                    @change="lenthheker($event, 'proPassword')"
                  />
                  <span style="color: #f21000" id="proPassword"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Mobile <span style="color: #f21000">*</span></label>
                  <input
                    type="number"
                    style="height: 30px"
                    v-model="professional_form.phone"
                    class="form-control"
                    placeholder="Phone"
                    @change="lenthheker($event, 'phonePro')"
                    required
                  />
                  <span style="color: #f21000" id="phonePro"></span>
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.phone">{{
                        professional_form.errors.error.phone[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Alt Mobile</label>
                  <input
                    type="number"
                    style="height: 30px"
                    v-model="professional_form.alternativePhone"
                    class="form-control"
                    placeholder="Alternative Phone"
                    @change="lenthheker($event, 'altPhonePro')"
                  />
                  <span style="color: #f21000" id="altPhonePro"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>DOB <span style="color: #f21000">*</span></label>
                  <input
                    style="height: 30px"
                    v-model="professional_form.dob"
                    placeholder="Date of Birth"
                    class="textbox-n form-control"
                    type="text"
                    onfocus="(this.type='date')"
                    id="date"
                    @change="dateCheker($event, 'doBDate')"
                    required
                  />
                  <span style="color: #f21000" id="doBDate"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Specialization <small style="color: red">*</small></label>
                  <input
                    requried
                    style="height: 30px"
                    type="text"
                    class="form-control"
                    v-model="professional_form.specialization"
                    placeholder="Specialization "
                    required
                  />
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Profassional Type</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.professional_type_id"
                    class="form-select"
                    aria-label="Default select example"
                    required
                  >
                    <option
                      v-for="(typeprofessional, index) in professionalType"
                      :key="index"
                      :value="typeprofessional.id"
                    >
                      {{ typeprofessional.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Address</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.address"
                    class="form-control"
                    placeholder="Address"
                    @change="lenthheker($event, 'profesionalAddress')"
                  />
                  <span style="color: #f21000" id="profesionalAddress"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>State</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.state_id"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>City <span style="color: #f21000">*</span></label>
                  <input
                    required
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.city"
                    class="form-control"
                    placeholder="City"
                    @change="lenthheker($event, 'profesionalCity')"
                  />
                  <span style="color: #f21000" id="profesionalCity"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Pin <span style="color: #f21000">*</span></label>
                  <input
                    required
                    style="height: 30px"
                    type="number"
                    v-model="professional_form.pin"
                    class="form-control"
                    placeholder=" Pin"
                    @change="lenthheker($event, 'pinCode')"
                  />
                  <span style="color: #f21000" id="pinCode"></span>
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.pin">{{
                        professional_form.errors.error.pin[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Status</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.loginStatus"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option value="true">ACTIVE</option>
                    <option value="false">INACTIVE</option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Gender <span style="color: #f21000">*</span></label>
                  <select
                    required
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.gender"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option vselected disabled>Select...</option>
                    <option value="male">MALE</option>
                    <option value="female">FEMALE</option>
                    <option value="other">OTHER</option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Bussiness Address</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.businessAddress"
                    class="form-control"
                    placeholder="Business Address	"
                    @change="lenthheker($event, 'businessAddress')"
                  />
                  <span style="color: #f21000" id="businessAddress"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Bussiness State</label>
                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.business_state_id"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Bussiness City</label>
                  <input
                    style="height: 30px"
                    type="text"
                    v-model="professional_form.businessCity"
                    class="form-control"
                    placeholder="Bussiness City"
                    @change="lenthheker($event, 'bussinessCity')"
                  />
                  <span style="color: #f21000" id="bussinessCity"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Business Pin</label>
                  <input
                    style="height: 30px"
                    maxlength="6"
                    type="number"
                    v-model="professional_form.businessPin"
                    class="form-control"
                    placeholder=" Pin"
                    @change="lenthheker($event, 'businessPinCode')"
                  />
                  <span style="color: #f21000" id="businessPinCode"></span>
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Firm Name</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.orgnization"
                    class="form-control"
                    placeholder="Firm Name"
                  />
                  <!-- <span style="color: #f21000" id="businessName"></span> -->
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.orgnization">{{
                        professional_form.errors.error.orgnization[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Member Ship No</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.memberShipNo"
                    class="form-control"
                    placeholder="Member Ship No"
                  />
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Cop No</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.copNo"
                    class="form-control"
                    placeholder="Cop No"
                  />
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>FRN No.</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.frnNo"
                    class="form-control"
                    placeholder="FRN NO "
                  />
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>TAN</label>
                  <input
                    type="tel"
                    style="height: 30px"
                    v-model="professional_form.tdsNo"
                    class="form-control"
                    placeholder="TAN "
                  />
                </div>
              </div>

              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>GST No.</label>
                  <input
                    style="height: 30px"
                    type="tel"
                    v-model="professional_form.gstNo"
                    class="form-control"
                    placeholder="GST NO."
                    @change="lenthheker($event, 'gstinVal')"
                  />
                  <span style="color: #f21000" id="gstinVal"></span>
                </div>
              </div>
              <div class="col-xl-3 col-md-3 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Professional Category</label>
                  <!-- <input
                    style=" height: 30px"
                    type="tel"
                    v-model="professional_form.professionalCategory"
                    class="form-control"
                    placeholder="Professional Category"
                  /> -->

                  <select
                    style="height: 30px; padding: 5px 5px 5px 5px"
                    v-model="professional_form.professionalCategory"
                    class="form-select"
                    aria-label="Default select example"
                  >
                    <option>Professional Category...</option>
                    <option value="internal">INTERNAL</option>
                    <option value="external">EXTERNAL</option>
                  </select>
                  <small style="color: red" v-if="professional_form.errors"
                    ><span v-if="professional_form.errors.error"
                      ><span v-if="professional_form.errors.error.professionalCategory">{{
                        professional_form.errors.error.professionalCategory[0]
                      }}</span></span
                    ></small
                  >
                </div>
              </div>

              <div class="col-xl-12 col-md-12 col-12" style="padding: 5px">
                <div class="from-group">
                  <label>Remark</label>
                  <textarea
                    v-model="professional_form.remark"
                    class="form-control"
                    rows="4"
                    placeholder="Remark"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white btn-sm"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal approoveKycModal-->

  <div
    class="modal fade"
    id="approoveKycModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLabel">
            <strong class="text">Approve Kyc</strong>
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid table-scroll">
            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="table-wrapper-scroll-y text tr-head rounded-circle">
                <th>+</th>
                <th>Ducument Name</th>
                <th class="text-end">Download</th>
              </tr>

              <tbody>
                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharFrontImg"
                      :src="adharFrontImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharFront">Aadhaar Front&nbsp;&nbsp;</b>
                    <span>{{ this.aadharNoForKyc }}</span>
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      @change="aadharKycApprovedSave()"
                      class="form-select"
                      v-model="aadharapproveKyc.status"
                    >
                      <option value="approve">Approve</option>
                      <!-- <option value="reject">Reject</option> -->
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <!-- <label for="AdharFront"> -->
                    <button
                      @click="
                        extensionkycAdharFront.toLowerCase() == 'pdf'
                          ? openWindow(adharFrontImgPdf)
                          : openWindow(adharFrontImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img
                      style="width: 30px; height: 30px"
                      v-if="adharBackImg"
                      :src="adharBackImg"
                    />
                  </td>

                  <td class="text-truncate text">
                    <b for="AdharBack">Aadhaar Back</b>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycAdharBack.toLowerCase() == 'pdf'
                          ? openWindow(adharBackImgPdf)
                          : openWindow(adharBackImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <img style="width: 30px; height: 30px" v-if="panImg" :src="panImg" />
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Profesional Pan &nbsp;&nbsp;</b>
                    <span> {{ this.panNoForKyc }}</span>
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      v-model="panapproveKyc.status"
                      @change="panKycApprovedSave()"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      @click="
                        extensionkycPanPhoto.toLowerCase() == 'pdf'
                          ? openWindow(panImgPdf)
                          : openWindow(panImg)
                      "
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>
                    <!-- <img style="width: 50px; height: 50px" v-if="panImg" :src="panImg" /> -->
                  </td>

                  <td class="text-truncate text">
                    <b for="panphoto">Kyc</b>
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      v-model="approveKyc.status"
                      class="form-select"
                    >
                      <option value="approve">Approve</option>
                      <option value="cancel" selected>Cancel</option>
                    </select>
                    <br />
                    <textarea
                      v-model="approveKyc.remark"
                      rows="2"
                      class="form-control"
                      placeholder="Remark"
                    ></textarea>
                  </td>
                  <td class="text-truncate text-end">
                    <button
                      type="button"
                      @click="professionalKycApprovedSave"
                      class="btn btns text-white btn-sm"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    >
                      <font-awesome-icon icon="floppy-disk" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <!-- <button
                type="submit"
                class="btn text-white btn-sm"
                style="background-color: #00364f"
              >
                Confirm
              </button> -->
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form
          id="form-modal-todo"
          class="todo-modal needs-validation"
          novalidate
          onsubmit="return false"
        >
          <div
            class="modal-header align-items-center mb-1"
            style="background-color: #00364f"
          >
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div
              class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white"
            >
              <span class="todo-item-favorite cursor-pointer me-75 text-white"
                ><i data-feather="star" class="font-medium-2"></i
              ></span>
              <i
                data-feather="x"
                class="cursor-pointer"
                data-bs-dismiss="modal"
                stroke-width="3"
                >X</i
              >
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="code"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1"> Code </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="name"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="name"
                  />
                  <label class="form-check-label" for="name"> Name </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="email"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="email"
                  />
                  <label class="form-check-label" for="email"> email </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="phone"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="phone"
                  />
                  <label class="form-check-label" for="phone"> phone </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input
                    @click="valueNullFilter()"
                    v-model="filterType"
                    value="all"
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="all"
                  />
                  <label class="form-check-label" for="all"> All </label>
                </div>
              </li>
            </ul>

            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <!-- <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'" class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
              <input
                :type="filterType == 'phone' ? 'number' : 'text'"
                v-if="filterType != 'all'"
                class="form-control"
                v-model="filterValue"
                id="exampleFormControlInput1"
                placeholder="Enter Value"
              />
              <!-- <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value"> -->
            </div>

            <button
              @click="loadprofessional()"
              type="button"
              class="btn btn-success btn-sm"
            >
              Success
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
var _ = require("lodash");
import Banner from "../../../components/super-admin/comman/Banner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "SuperAdminProfessional",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      qualificationids : [],
      filterValue: "",
      filterType: "",
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      panVarifyingStatus: "false",
      submitStatus: "false",

      professionalName: "false",
      phonePro: "false",
      altPhonePro: "false",
      doBDate: "false",
      profesionalCity: "false",
      profesionalAddress: "false",
      bussinessCity: "false",
      businessAddress: "false",
      pinCode: "false",
      businessPinCode: "false",
      businessName: "false",
      gstinVal: "false",

      proPassword: "false",
      // doiDate: "false",

      aadharNoForKyc: "",
      panNoForKyc: "",

      loading: false,
      extension: "",
      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      extensionkycPassbookPhoto: "",
      extensionProfile: "",
      professional_services_charge_form: {
        professional_id: null,
        professionalFee: "",
        status: "",
        service_id: null,
        professionalGstRequired: "true",
        remark: "",
      },
      photo: this.$store.state.placeholderImg,
      kycModalHeading: "",
      adharFrontImg: this.$store.state.placeholderImg,
      adharBackImg: this.$store.state.placeholderImg,
      panImgPdf: "",
      adharFrontImgPdf: "",
      adharBackImgPdf: "",
      panImg: this.$store.state.placeholderImg,
      passBookImg: this.$store.state.placeholderImg,
      states: [],
      qualifications: [],
      msgImgError: "",
      photoPreImg: this.$store.state.placeholderImg,
      bussinessPanPreImg: this.$store.state.placeholderImg,
      logoPreImg: this.$store.state.placeholderImg,
      allprofessionals: [],
      services: [],
      professional_form: {
        professionalCategory: "",
        professional_type_id: null,
        photo: "",
        code: "",
        name: "",
        email: "",
        password: "",
        specialization: "",
        phone: "",
        address: "",
        state_id: 9,
        alternativePhone: "",
        dob: "",
        gender: "male",
        remark: "",
        city: "",
        orgnization: "",
        memberShipNo: "",
        designation_id: null,
        copNo: "",
        frnNo: "",
        tdsNo: "",
        gstNo: "",
        businessAddress: "",
        businessCity: "",
        business_state_id: 9,
        businessPin: "",
        logo: "",
        businessPan: "",
        businessPanPhoto: "",

        loginStatus: "true",
        pin: null,
        errors: {},
      },
      form: new Form({
        panNo: null,
        panPhoto: "",
        accountType: "",
        accountNo: null,
        passbookPhoto: "",
        aadharNo: null,
        aadharFrontPhoto: "",
        aadharBackPhoto: "",
        remark: "",
      }),
      edit: "false",
      professionalType: [],
      professionalServices: [],
      profServices: [],
      professional_id: null,
      professionalkycs: [],
      professionalKycVal: "false",
      approveKyc: new Form({
        professional_id: null,
        type: "kyc",
        status: "cancel",
        remark: "",
      }),
      aadharapproveKyc: new Form({
        professional_id: null,
        type: "aadhar",
        status: "cancel",
        remark: "Aadhar Kyc",
      }),
      panapproveKyc: new Form({
        professional_id: null,
        type: "pan",
        status: "cancel",
        remark: "",
      }),
      accountapproveKyc: new Form({
        professional_id: null,
        type: "account",
        status: "cancel",
        remark: "",
      }),
      professionalKycAddar: "false",

      formQualification : {
        qualification_id : [],
      }
    };
  },
  methods: {
    professionaQualification(event, value) {
  console.log(event.target.checked);

  if (event.target.checked) {
    this.formQualification.qualification_id = value.id;
    this.formQualification.professional_id = this.professional_id;
    this.formQualification.status = 'true';

    this.loading = true;
    this.$axios
          .post("superadmin/professionalqualification", this.formQualification, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Step Created  Succesfully", {
              autoClose: 1000,
            });
            // this.stepsGet();
            // this.form = {};
            // this.form.errors = {};
            // $("#exampleModal").modal("hide");
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
                  this.$swal.fire({
                    position: "top-center",
                    icon: "error", 
                    title: "<small>"+error.response.data.error+"</small>",
                    showConfirmButton: true,
                    confirmButtonColor: "#00364f",
        
                  });

              });
          




  
  } else if (!event.target.checked) {
    
    this.loading = true;
    this.$axios
          .delete(`superadmin/professionalqualification/${value.professionalqualification_id}`, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Deleted  Succesfully", {
              autoClose: 1000,
            });
            
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
                  this.$swal.fire({
                    position: "top-center",
                    icon: "error", 
                    title: "<small>"+error.response.data.error+"</small>",
                    showConfirmButton: true,
                    confirmButtonColor: "#00364f",
        
                  });

              });
            //  plesae add  delete code here 

    console.log(this.formQualification.qualification_id);
  }
}
,
    valueNullFilter() {
      this.filterValue = "";
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadprofessional();
    },
    getQualifications(professional) {
        this.professional_id = professional.id

        this.professionalgetQualification()

      this.$axios
        .get("superadmin/qualification", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.qualifications = res.data.data.data;
        });









    },


professionalgetQualification(){
  this.$axios
        .get(`superadmin/professionalqualification?professional_id=${this.professional_id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          // this.qualifications = res.data.data.data;
          console.log(res.data.data.data)
var proQua = res.data.data.data




  // ---------------------------------------------------
  var serModule = this.qualifications;

_.forEach(proQua, function (value) {
  if (
    _.find(serModule, function (o) {
      return o.id == value.qualification_id;
    })
  ) {
    var index = _.findIndex(serModule, function (o) {
      return o.id == value.qualification_id;
    });
    serModule[index].professionalqualification_id = value.id;

  }
});
this.qualifications = serModule;
console.log('=============================================================')
console.log(this.qualifications)

   

        });

},



    
    businessPanVeryfied() {
      var data = this.form.panNo;
      if (data.length == 10) {
        this.submitStatus = "false";
        $("#adminPanVeryfied").html("<small></small>");
        // this.msgPanError = "";
        this.loading = true;
        this.$axios
          .post(
            "superadmin/verifypan",
            { panNo: this.form.panNo },
            { headers: { Authorization: "Bearer " + localStorage.accessToken } }
          )
          .then((res) => {
            if (res.data.data.message) {
              // this.msgPanError = res.data.data.message;
              this.submitStatus = "true";
              $("#adminPanVeryfied").html("<small>" + res.data.data.message + "</small>");
            }
            if (res.data.data.data) {
              $("#adminPanVeryfied").html(
                "<small>" + res.data.data.data.status + " Pan </small>"
              );
            }
            if (res.data.data.data.full_name) {
              this.submitStatus = "false";
              $("#adminPanVeryfied").html("<small></small>");
              this.panVarifyingStatus = "true";
              // this.$swal.fire({
              //   position: "top-end",
              //   icon: "success",
              //   title: "Pan Verified Succesfully",
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              toast.success("Pan Verified Succesfully", {
                autoClose: 1000,
              });
            }
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
      } else {
        this.submitStatus == "true";
        $("#adminPanVeryfied").html("<small> Pan required 10 Digits</small>");
      }

      // this.panNo = ''
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        $("#adminPanVeryfied").html("<small>special characters are not allowed</small>");
        e.preventDefault();
      } else {
        $("#adminPanVeryfied").html("<small></small>");
      }
    },
    valueNullProfassional() {
      this.professional_form = {};
      this.professional_form.errors = {};
      this.photoPreImg = this.$store.state.placeholderImg;
      this.professional_form.state_id = 1;
      this.professional_form.business_state_id = 1;
      this.professional_form.loginStatus = "true";
      this.professional_form.gender = "male";
      this.professional_form.professional_type_id = this.findMinVal(
        this.professionalType
      );

      this.professional_form.professionalCategory = "internal";
      this.edit = "false";
      this.msgImgError = "";
      // alert("done");

      $("#gstinVal").html("<small></small>");
      $("#proPassword").html("<small></small>");
      $("#pinCode").html("<small></small>");
      $("#businessPinCode").html("<small></small>");
      $("#bussinessCity").html("<small></small>");
      $("#businessAddress").html("<small></small>");
      $("#altPhonePro").html("<small></small>");

      $("#phonePro").html("<small></small>");

      $("#businessName").html("<small></small>");
      $("#professionalName").html("<small></small>");
      $("#professionalKycAddar").html("<small></small>");
    },
    findMinVal(data) {
      var arr = [];

      for (var i = 0; i < data.length; i++) {
        arr.push(data[i].id);
      }

      var r = Math.min.apply(Math, arr);
      return r;
    },
    checkFileExtension() {
      var fileName;
      fileName = document.querySelector("#chooseFile").value;
      this.extension = fileName.split(".").pop();
      // alert(this.extension);
    },
    professionalKycLoad() {
      this.$axios
        .get("superadmin/professionalkyc", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.professionalkycs = res.data.data.data;
        });
    },
    professionalkycValueAsign(value) {
      this.form = {};
      this.panImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      $("#professionalKycAddar").html("<small></small>");
      this.professional_id = value.id;
      this.form.professional_type_id = value.professional_type_id;
      this.form.panNo = null;
      this.form.panPhoto = "";
      this.form.accountType = null;
      this.form.aadharNo = null;
      this.form.remark = "";
      this.form.aadharBackPhoto = "";
      this.form.aadharFrontPhoto = "";
      this.form.passbookPhoto = "";
      this.form.accountNo = null;
      if (value.professional_kyc == null) {
        this.panVarifyingStatus = "false";
        this.kycModalHeading = "Professional Kyc";
        this.professionalKycVal = "false";
      } else {
        this.panVarifyingStatus = "true";
        this.kycModalHeading = "Professional Kyc";
        this.form = value.professional_kyc;
        if (value.professional_kyc.panPhoto) {
          if (value.professional_kyc.panPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/" +
              value.professional_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/" +
                value.professional_kyc.panPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/thumbs/" +
              value.professional_kyc.panPhoto
            ) {
              this.panImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/thumbs/" +
                value.professional_kyc.panPhoto;
            }

            this.extensionkycPanPhoto = this.panImg.split(".").pop();

            if (
              this.extensionkycPanPhoto == "pdf" ||
              this.extensionkycPanPhoto == "PDF"
            ) {
              this.panImg = "/assets/image/pdf.png";
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }
        }
        if (value.professional_kyc.passbookPhoto) {
          if (value.professional_kyc.passbookPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/" +
              value.professional_kyc.passbookPhoto
            ) {
              this.passBookImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/" +
                value.professional_kyc.passbookPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/thumbs/" +
              value.professional_kyc.passbookPhoto
            ) {
              this.passBookImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/thumbs/" +
                value.professional_kyc.passbookPhoto;
            }

            this.extensionkycPassbookPhoto = this.passBookImg.split(".").pop();

            if (
              this.extensionkycPassbookPhoto == "pdf" ||
              this.extensionkycPassbookPhoto == "PDF"
            ) {
              this.passBookImg = "/assets/image/pdf.png";
            }
          } else {
            this.passBookImg = this.$store.state.placeholderImg;
          }
        }
        if (value.professional_kyc.aadharFrontPhoto) {
          if (value.professional_kyc.aadharFrontPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/" +
              value.professional_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/" +
                value.professional_kyc.aadharFrontPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/thumbs/" +
              value.professional_kyc.aadharFrontPhoto
            ) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/thumbs/" +
                value.professional_kyc.aadharFrontPhoto;
            }

            this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

            if (
              this.extensionkycAdharFront == "pdf" ||
              this.extensionkycAdharFront == "PDF"
            ) {
              this.adharFrontImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }
        }

        if (value.professional_kyc.aadharBackPhoto) {
          if (value.professional_kyc.aadharBackPhoto != null) {
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/" +
              value.professional_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/" +
                value.professional_kyc.aadharBackPhoto;
            }
            if (
              this.$store.state.imgUrl +
              "/professional/" +
              value.id +
              "/thumbs/" +
              value.professional_kyc.aadharBackPhoto
            ) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/professional/" +
                value.id +
                "/thumbs/" +
                value.professional_kyc.aadharBackPhoto;
            }

            this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

            if (
              this.extensionkycAdharBack == "pdf" ||
              this.extensionkycAdharBack == "PDF"
            ) {
              this.adharBackImg = "/assets/image/pdf.png";
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }
        }

        // if (this.form.aadharBackPhoto) {
        //   if (this.form.aadharBackPhoto != null) {
        //     if (
        //       this.$store.state.imgUrl +
        //       "/professional/" +
        //       value.id +
        //       "/" +
        //       value.professional_kyc.aadharBackPhoto
        //     ) {
        //       this.adharBackImg =
        //         this.$store.state.imgUrl +
        //         "/professional/" +
        //         value.id +
        //         "/" +
        //         value.professional_kyc.aadharBackPhoto;
        //     }
        //     if (
        //       this.$store.state.imgUrl +
        //       "/professional/" +
        //       value.id +
        //       "/thumbs/" +
        //       value.professional_kyc.aadharBackPhoto
        //     ) {
        //       this.adharBackImg =
        //         this.$store.state.imgUrl +
        //         "/professional/" +
        //         value.id +
        //         "/thumbs/" +
        //         value.professional_kyc.aadharBackPhoto;
        //     }

        //     this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        //     if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
        //       this.adharBackImg = "/assets/image/pdf.png";
        //     }
        //   } else {
        //     this.adharBackImg = this.$store.state.placeholderImg;
        //   }
        // }

        this.professionalKycVal = "true";
      }
    },
    professionalKycCreate() {
      if (
        this.professionalKycAddar == "false" &&
        this.msgImgError == "" &&
        this.submitStatus == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("professional_id", this.professional_id);
        this.$axios
          .post("superadmin/professionalkyc", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("Kyc Created Succesfully", {
              autoClose: 1000,
            });
            this.loadprofessional();
            this.form = {};
            $("#kycModal").modal("hide");
            //    this.$router.push({ name: 'Admins' })
          });
      }
    },
    uploadAdharFront(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharFront").value;
      this.extensionkycAdharFront = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycAdharFront == "png" ||
          this.extensionkycAdharFront == "jpeg" ||
          this.extensionkycAdharFront == "jpg" ||
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PNG" ||
          this.extensionkycAdharFront == "JPEG" ||
          this.extensionkycAdharFront == "JPG" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharFrontPhoto = e.target.files["0"];

          if (
            this.extensionkycAdharFront == "pdf" ||
            this.extensionkycAdharFront == "PDF"
          ) {
            this.adharFrontImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadAdharBack(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#AdharBack").value;
      this.extensionkycAdharBack = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycAdharBack == "png" ||
          this.extensionkycAdharBack == "jpeg" ||
          this.extensionkycAdharBack == "jpg" ||
          this.extensionkycAdharBack == "pdf" ||
          this.extensionkycAdharBack == "PNG" ||
          this.extensionkycAdharBack == "JPEG" ||
          this.extensionkycAdharBack == "JPG" ||
          this.extensionkycAdharBack == "PDF"
        ) {
          this.adharBackImg = URL.createObjectURL(e.target.files["0"]);
          this.form.aadharBackPhoto = e.target.files["0"];

          if (
            this.extensionkycAdharBack == "pdf" ||
            this.extensionkycAdharBack == "PDF"
          ) {
            this.adharBackImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Back File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    uploadPanPhoto(e) {
      this.msgImgError = "";
      var fileName;
      fileName = document.querySelector("#panphoto").value;
      this.extensionkycPanPhoto = fileName.split(".").pop();

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        if (
          this.extensionkycPanPhoto == "png" ||
          this.extensionkycPanPhoto == "jpeg" ||
          this.extensionkycPanPhoto == "jpg" ||
          this.extensionkycPanPhoto == "pdf" ||
          this.extensionkycPanPhoto == "PNG" ||
          this.extensionkycPanPhoto == "JPEG" ||
          this.extensionkycPanPhoto == "JPG" ||
          this.extensionkycPanPhoto == "PDF"
        ) {
          this.panImg = URL.createObjectURL(e.target.files["0"]);
          this.form.panPhoto = e.target.files["0"];

          if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
            this.panImg = "/assets/image/pdf.png";
          }
        } else {
          this.msgImgError = "Aadhar Front File type must JPG ,JPEG, PNG , PDF ";
        }
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },
    professionalUpdateKyc() {
      if (
        this.professionalKycAddar == "false" &&
        this.msgImgError == "" &&
        this.submitStatus == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        formData.append("professional_id", this.professional_id);
        this.loading = true;
        this.$axios
          .post(`superadmin/professionalkyc/${this.form.id}?_method=PUT`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("Kyc Updated Succesfully", {
              autoClose: 1000,
            });
            this.loadprofessional();
            this.form = {};
            $("#kycModal").modal("hide");
            //    this.$router.push({ name: 'Admins' })
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.professional_form.errors = error.response.data;
          });
      }
    },
    openWindow(url) {
      window.open(`${url}`);
    },
    professionalkycValueApprove(value) {
      console.log(value);
      this.panNoForKyc = value.professional_kyc.panNo;
      this.aadharNoForKyc = value.professional_kyc.aadharNo;

      this.approveKyc.remark = "";
      this.accountapproveKyc.remark = "";
      this.panapproveKyc.remark = "";
      this.aadharapproveKyc.remark = "";
      this.panImg = this.$store.state.placeholderImg;
      this.adharBackImg = this.$store.state.placeholderImg;
      this.adharFrontImg = this.$store.state.placeholderImg;

      this.approveKyc.status = value.professional_kyc.kycStatus;
      this.panapproveKyc.status = value.professional_kyc.panStatus;
      this.aadharapproveKyc.status = value.professional_kyc.aadharStatus;

      this.approveKyc.professional_id = value.id;
      this.accountapproveKyc.professional_id = value.id;
      this.panapproveKyc.professional_id = value.id;
      this.aadharapproveKyc.professional_id = value.id;

      this.approveKyc.remark = value.professional_kyc.remark;
      // this.accountapproveKyc.remark = value.remark
      // this.panapproveKyc.remark = value.remark
      // this.aadharapproveKyc.remark = value.remark

      // this.form = value.admin_kyc

      if (value.professional_kyc.panPhoto != null) {
        // this.panImg = this.$store.state.imgUrl + '/professional/' + value.id + '/thumbs/' + value.professional_kyc.panPhoto

        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/thumbs/" +
          value.professional_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/thumbs/" +
            value.professional_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/" +
          value.professional_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/" +
            value.professional_kyc.panPhoto;
        }
        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (value.professional_kyc.aadharFrontPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/thumbs/" +
          value.professional_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/thumbs/" +
            value.professional_kyc.aadharFrontPhoto;
        }

        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/thumbs/" +
          value.professional_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/" +
            value.professional_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (
          this.extensionkycAdharFront == "pdf" ||
          this.extensionkycAdharFront == "PDF"
        ) {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (value.professional_kyc.aadharBackPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/thumbs/" +
          value.professional_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/thumbs/" +
            value.professional_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/professional/" +
          value.id +
          "/" +
          value.professional_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/professional/" +
            value.id +
            "/" +
            value.professional_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }
    },
    professionalKycApprovedSave() {
      var kyc;
      if (this.approveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.approveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.approveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveprofessionalkyc", this.approveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Professional Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
          this.form = {};
          //    this.$router.push({ name: 'Admins' })
          this.loadprofessional();
          $("#approoveKycModal").modal("hide");
        })
        .finally(() => (this.loading = false));
    },
    aadharKycApprovedSave() {
      var kyc;
      if (this.aadharapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.aadharapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.aadharapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveprofessionalkyc", this.aadharapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          toast.success("Aadhar Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
        })
        .finally(() => (this.loading = false));
    },

    panKycApprovedSave() {
      var kyc;
      if (this.panapproveKyc.status == "approve") {
        kyc = "Approved";
      } else if (this.panapproveKyc.status == "reject") {
        kyc = "Rejected";
      } else if (this.panapproveKyc.status == "cancel") {
        kyc = "Cancelled";
      }
      this.loading = true;
      this.$axios
        .post("superadmin/approveprofessionalkyc", this.panapproveKyc, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;

          toast.success("Pan Kyc " + kyc + " Succesfully", {
            autoClose: 1000,
          });
        })
        .finally(() => (this.loading = false));
    },
    profileImageUpload(e) {
      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Profile Image Size Too Big 2MB";
        return false;
      } else {
        var fileName;
        fileName = document.querySelector("#file-input1").value;
        this.extensionProfile = fileName.split(".").pop();
        if (
          this.extensionProfile == "png" ||
          this.extensionProfile == "jpeg" ||
          this.extensionProfile == "jpg" ||
          this.extensionProfile == "PNG" ||
          this.extensionProfile == "JPEG" ||
          this.extensionProfile == "JPG"
        ) {
          // this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
          // this.form.photo = e.target.files["0"];

          this.photoPreImg = URL.createObjectURL(e.target.files["0"]);
          this.professional_form.photo = e.target.files["0"];
        } else {
          this.msgImgError = "File Type Must JPG , PNG, JPEG";
        }
      }
    },

    bussinessPanUpload(e) {
      var fileName;
      fileName = document.querySelector("#file-input3").value;
      this.extension = fileName.split(".").pop();
      // alert(this.extension);

      this.msgImgError = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError = "Logo Image Size Too Big 2MB";
        return false;
      }
      this.bussinessPanPreImg = URL.createObjectURL(e.target.files["0"]);
      if (this.extension == "pdf") {
        this.bussinessPanPreImg = "";
      }
      this.professional_form.businessPanPhoto = e.target.files["0"];
    },
    logoUpload(e) {
      this.msgImgError = "";

      if (e.target.files["0"].size <= 2 * (1024 * 1024)) {
        this.logoPreImg = URL.createObjectURL(e.target.files["0"]);
        this.professional_form.logo = e.target.files["0"];
        return false;
      } else {
        this.msgImgError = "File Size Too Big 2MB";
      }
    },

    saveprofessional() {
      if (
        this.msgImgError == "" &&
        this.professionalName == "false" &&
        this.phonePro == "false" &&
        this.altPhonePro == "false" &&
        this.profesionalAddress == "false" &&
        this.profesionalCity == "false" &&
        this.businessPinCode == "false" &&
        this.pinCode == "false" &&
        this.gstinVal == "false" &&
        this.businessName == "false" &&
        this.doBDate == "false" &&
        this.proPassword == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.professional_form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.professional_form[keys[n]] !== null) {
            formData.append(keys[n], this.professional_form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post("superadmin/professional", formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;
            toast.success("New professional Created  Succesfully", {
              autoClose: 1000,
            });

            this.professional_form = {};
            this.professional_form.errors = {};
            this.photoPreImg = this.$store.state.placeholderImg;
            this.loadprofessional();
            $("#exampleModal").modal("hide");
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            this.professional_form.errors = error.response.data;
          });
      }
    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      var data = event.target.value;

      if (type == "doBDate") {
        if (currentDateWithFormat < data) {
          this.doBDate = "true";

          $("#doBDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDate = "false";
          $("#doBDate").html("<small></small>");
        }
      }
      // }
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "professionalKycAddar") {
        if (data.length != 12) {
          this.professionalKycAddar = "true";

          $("#professionalKycAddar").html("<small>Aadhar required  12 Digits</small>");
        }
        if (data.length == 12) {
          this.professionalKycAddar = "false";
          $("#professionalKycAddar").html("<small></small>");
        }
      }
      if (type == "professionalName") {
        if (data.length < 3) {
          this.professionalName = "true";
          $("#professionalName").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.professionalName = "false";
          $("#professionalName").html("<small></small>");
        }
      }
      if (type == "businessName") {
        if (data.length < 3) {
          this.businessName = "true";
          $("#businessName").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.businessName = "false";
          $("#businessName").html("<small></small>");
        }
      }

      if (type == "phonePro") {
        if (data.length != 10) {
          this.phonePro = "true";
          $("#phonePro").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.phonePro = "false";
          $("#phonePro").html("<small></small>");
        }
      }
      if (type == "altPhonePro") {
        if (data.length != 10) {
          this.altPhonePro = "true";
          $("#altPhonePro").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          this.altPhonePro = "false";
          $("#altPhonePro").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "profesionalCity") {
        if (data.length < 3) {
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      if (type == "businessAddress") {
        if (data.length < 3) {
          if (data.length == 0) {
            this.businessAddress = "false";
            $("#businessAddress").html("<small></small>");
          } else {
            this.businessAddress = "true";
            $("#businessAddress").html(
              "<small>Address required minimum 3 letters</small>"
            );
          }
        }
        if (data.length >= 3) {
          this.businessAddress = "false";
          $("#businessAddress").html("<small></small>");
        }
      }

      if (type == "bussinessCity") {
        if (data.length < 3) {
          if (data.length == 0) {
            this.bussinessCity = "false";
            $("#bussinessCity").html("<small></small>");
          } else {
            this.bussinessCity = "true";
            $("#bussinessCity").html("<small>City required minimum 3 letters</small>");
          }
        }
        if (data.length >= 3) {
          this.bussinessCity = "false";
          $("#bussinessCity").html("<small></small>");
        }
      }

      if (type == "businessPinCode") {
        if (data.length != 6) {
          this.businessPinCode = "true";
          $("#businessPinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          this.businessPinCode = "false";
          $("#businessPinCode").html("<small></small>");
        }
      }

      if (type == "pinCode") {
        if (data.length != 6) {
          if (data.length == 0) {
            this.pinCode = "false";
            $("#pinCode").html("<small></small>");
          } else {
            this.pinCode = "true";
            $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
          }
        }
        if (data.length == 6) {
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }

      if (type == "proPassword") {
        if (data.length < 6) {
          this.proPassword = "true";
          $("#proPassword").html("<small>Password Minimum 6 Digits Required</small>");
        }
        if (data.length >= 6) {
          this.proPassword = "false";
          $("#proPassword").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
          if (data.length == 0) {
            this.gstinVal = "false";
            $("#gstinVal").html("<small></small>");
          } else {
            this.gstinVal = "true";
            $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
          }
        }
        if (data.length == 15) {
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }
    },
    professionalEdit(professional) {
      this.doBDate = "false";
      $("#doBDate").html("<small></small>");
      this.professionalKycAddar = "false";
      $("#professionalKycAddar").html("<small></small>");
      this.professionalName = "false";
      $("#professionalName").html("<small></small>");
      this.businessName = "false";
      $("#businessName").html("<small></small>");
      this.phonePro = "false";
      $("#phonePro").html("<small></small>");
      this.altPhonePro = "false";
      $("#altPhonePro").html("<small></small>");
      this.profesionalAddress = "false";
      $("#profesionalAddress").html("<small></small>");
      this.profesionalCity = "false";
      $("#profesionalCity").html("<small></small>");
      this.businessAddress = "false";
      $("#businessAddress").html("<small></small>");
      this.bussinessCity = "false";
      $("#bussinessCity").html("<small></small>");
      this.businessPinCode = "false";
      $("#businessPinCode").html("<small></small>");

      this.pinCode = "false";
      $("#pinCode").html("<small></small>");
      this.phonePro = "false";
      $("#phonePro").html("<small></small>");
      this.gstinVal = "false";
      $("#gstinVal").html("<small></small>");
      this.proPassword = "false";
      $("#proPassword").html("<small></small>");

      this.photoPreImg = this.$store.state.placeholderImg;
      this.professional_form = {};
      this.professional_form.errors = {};
      this.professional_id = professional.id;
      // this.professional_form = professional;

      this.professional_form.professionalCategory = professional.professionalCategory;
      this.professional_form.professional_type_id = professional.professional_type_id;
      this.professional_form.code = professional.code;
      this.professional_form.name = professional.name;
      this.professional_form.email = professional.email;
 
      this.professional_form.specialization = professional.specialization;
      this.professional_form.phone = professional.phone;
      this.professional_form.address = professional.address;
      this.professional_form.state_id = professional.state_id;
      this.professional_form.alternativePhone = professional.alternativePhone;
      this.professional_form.dob = professional.dob;
      this.professional_form.gender = professional.gender;
      this.professional_form.remark = professional.remark;
      this.professional_form.city = professional.city;
      this.professional_form.orgnization = professional.orgnization;
      this.professional_form.memberShipNo = professional.memberShipNo;
      this.professional_form.designation_id = professional.designation_id;
      this.professional_form.copNo = professional.copNo;
      this.professional_form.frnNo = professional.frnNo;
      this.professional_form.tdsNo = professional.tdsNo;
      this.professional_form.gstNo = professional.gstNo;

      this.professional_form.businessAddress = professional.businessAddress;
      this.professional_form.businessCity = professional.businessCity;
      this.professional_form.business_state_id = professional.business_state_id;
      this.professional_form.businessPin = professional.businessPin;
      this.professional_form.businessPan = professional.businessPan;
      this.professional_form.pin = professional.pin;
      this.professional_form.loginStatus = professional.loginStatus;

      if (professional.photo != null) {
        this.photoPreImg =
          this.$store.state.imgUrl +
          "/professional/" +
          professional.id +
          "/thumbs/" +
          professional.photo;
      } else {
        this.photoPreImg = this.$store.state.placeholderImg;
      }
      this.edit = "true";
    },
    professionalUpdate() {
      if (
        this.msgImgError == "" &&
        this.professionalName == "false" &&
        this.phonePro == "false" &&
        this.altPhonePro == "false" &&
        this.profesionalAddress == "false" &&
        this.profesionalCity == "false" &&
        this.businessPinCode == "false" &&
        this.pinCode == "false" &&
        this.gstinVal == "false" &&
        this.businessName == "false" &&
        this.doBDate == "false" &&
        this.proPassword == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.professional_form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.professional_form[keys[n]] !== null) {
            formData.append(keys[n], this.professional_form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`superadmin/professional/${this.professional_id}?_method=put`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            res;

            toast.success("professional Updated  Succesfully", {
              autoClose: 1000,
            });
            this.professional_form = {};
            this.professional_form.errors = {};
            this.photoPreImg = this.$store.state.placeholderImg;
            this.loadprofessional();
            $("#exampleModal").modal("hide");
          })
          .catch((error) => {
            this.professional_form.errors = error.response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    loadprofessionalType() {
      this.$axios
        .get("superadmin/professionaltype", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.professionalType = res.data.data.data;
        });
    },
    saveServiceChargesprofessional(service) {
      // alert('profid' + this.professional_services_charge_form.professional_id)
      this.professional_services_charge_form.service_id = service.id;

      // alert('before' + this.professional_services_charge_form.service_id)
      // for (var i = 0; i < this.profServices.length; i++) {
      //   if (service.id == this.profServices[i]) {
      //     this.professional_services_charge_form.status = "true";
      //   }
      // }
      if (
        this.professional_services_charge_form.professionalFee == "" &&
        service.professionalServiceFee != ""
      ) {
        this.professional_services_charge_form.professionalFee =
          service.professionalServiceFee;
        if (this.professional_services_charge_form.status) {
          this.professional_services_charge_form.status;
        } else {
          if (service.profStatus) {
            this.professional_services_charge_form.status = service.profStatus;
          }
        }
      }

      if (this.professional_services_charge_form.professionalFee) {
        console.log(this.professional_services_charge_form);
        var usrStr = "superadmin/professionalservice";
        if (service.professional_service_id) {
          usrStr = usrStr + "/" + service.professional_service_id;
          this.loading = true;
          this.$axios
            .put(usrStr, this.professional_services_charge_form, {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            })
            .then((res) => {
              res;
              toast.success("Professional service Updated Succesfully", {
                autoClose: 1000,
              });
              this.professional_services_charge_form.professionalFee = "";
              this.professional_services_charge_form.status = "";
              this.professionalServicesLoad();
            })
            .finally(() => (this.loading = false))
            .catch((error) => {
              var msg = "";
              if (error.response.data.error.professionalFee[0]) {
                msg = error.response.data.error.professionalFee[0];
              }
              if (error.response.data.error.status[0]) {
                msg = error.response.data.error.status[0];
              } else {
                msg = error.response.data.error.professionalFee;
              }
              this.$swal.fire({
                position: "top-center",
                icon: "error",
                title: "<small>" + msg + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
            });
        } else {
          this.loading = true;
          this.$axios
            .post(usrStr, this.professional_services_charge_form, {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            })
            .then((res) => {
              res;
              toast.success("Professional service Created Succesfully", {
                autoClose: 1000,
              });
              this.professional_services_charge_form.professionalFee = "";
              this.professional_services_charge_form.status = "";
              this.professionalServicesLoad();
            })
            .finally(() => (this.loading = false))
            .catch((error) => {
              var msg = "";

              if (error.response.data.error.status[0]) {
                msg = error.response.data.error.status[0];
              } else {
                msg = error.response.data.error;
              }
              this.$swal.fire({
                position: "top-center",
                icon: "error",
                title: "<small>" + msg + "</small>",
                showConfirmButton: true,
                confirmButtonColor: "#00364f",
              });
            });
        }
      } else {
        this.$swal.fire({
          position: "top-center",
          icon: "error",
          title: "<small> The professional fee field is required. </small>",
          showConfirmButton: true,
          confirmButtonColor: "#00364f",
        });
      }
    },

    professionalServiceCharge(service, event) {
      console.log(service);

      this.professional_services_charge_form.professionalFee = event.target.value;
      if (this.professional_services_charge_form.status) {
        this.professional_services_charge_form.status;
      } else {
        if (service.profStatus) {
          this.professional_services_charge_form.status = service.profStatus;
        }
      }
    },
    professionalServiceStatus(service, sts) {
      this.professional_services_charge_form.status = "";
      this.professional_services_charge_form.status = sts;
      console.log(sts);
      console.log(this.professional_services_charge_form);
      // if (event.target.checked == true) {
      //   this.professional_services_charge_form.status = sts;
      // } else if (event.target.checked == false) {
      //   this.professional_services_charge_form.status = "false";
      // }
    },
    getServices(professional) {
      this.loadAllServices();
      this.professional_id = professional.id;
      this.professional_services_charge_form.professional_id = professional.id;

      // this.professionalServicesLoad();
    },

    loadAllServices() {
      this.loading = true;
      this.$axios
        .get("superadmin/service?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.services = res.data.data;
          this.professionalServicesLoad();
        })
        .finally(() => (this.loading = false));
    },
    editServiceChargesprofessional() {
      for (let i = 0; i <= this.services.length; i++) {
        if (this.services[i].id == this.professionalServices[i].service_id) {
          return this.professionalServices[i].professionalFee;
        }
      }
    },
    professionalServicesLoad() {
      this.$axios
        .get(
          `/superadmin/professionalservice?all=all&professional_id=${this.professional_id}`,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          this.professionalServices = res.data.data;

          // ---------------------------------------------------
          var serModule = this.services;

          _.forEach(this.professionalServices, function (value) {
            if (
              _.find(serModule, function (o) {
                return o.id == value.service_id;
              })
            ) {
              var index = _.findIndex(serModule, function (o) {
                return o.id == value.service_id;
              });
              serModule[index].profStatus = value.status;
              serModule[index].professionalServiceFee = value.professionalFee;

              serModule[index].professional_service_id = value.id;
              serModule[index].professionalGstRequired = value.professionalGstRequired;
              serModule[index].remark = value.remark;
            }
          });
          this.services = serModule;
        });
    },
    loadprofessional(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        if (this.filterType == "all") {
          this.filterValue = "";
          this.filterType = "";
        }

        if (this.filterValue != null && this.filterType != "all") {
          if (this.filterType == "name") {
            pageUrl += `superadmin/professional?per_page=${this.per_page}&searchTerm=${this.filterValue}`;
          } else {
            pageUrl += `superadmin/professional?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
          }
        } else {
          pageUrl += `superadmin/professional?per_page=${this.per_page}`;
        }
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.allprofessionals = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadStates() {
      this.loading = true;
      this.$axios
        .get("superadmin/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.states = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle() {
      console.log("--------------------------");

      document.title = this.$store.state.superAdminPageTitles.professional;
      console.log(this.$store.state.superAdminPageTitles.professional);
      console.log("--------------------------");
    },
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadprofessional();
      this.loadprofessionalType();
      this.loadStates();
      this.professionalKycLoad();
   
      this.loadPageTitle();
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}

input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 5px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}

.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
